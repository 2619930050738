html,
body {
  padding: 0;
  margin: 0;
  position: relative;
  width: 100vw;
  height: 100vh;
  font-family: 'Roboto';
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}